export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="mx-auto mt-32 w-full px-4 sm:px-6 lg:px-8">
      <div className="border-t border-zinc-900/5 py-10 dark:border-white/10">
        <p className="mt-5 text-center text-sm leading-6 text-zinc-500">
          © {year} Handicapper.net, Inc. All rights reserved.
        </p>
        <div className="mt-16 flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-zinc-700 prose-a:text-zinc-600 hover:prose-a:text-zinc-500 dark:text-zinc-300 dark:prose-a:text-zinc-400 dark:prose-a:hover:text-zinc-300">
          <a href="/privacy">Privacy policy</a>
          <div className="h-4 w-px bg-zinc-500/20 dark:bg-zinc-500/80"></div>
          <a href="/terms">Terms and conditions</a>
        </div>
      </div>
    </footer>
  );
}
